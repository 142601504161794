<template>
  <div id="user-list">
    <v-row class="mb-5">
      <v-col v-for="total in overview" :key="total.title" cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.count }}
              </h2>
              <span>{{ $t(total.title) }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveUserTotalIcon(total.title).color" class="rounded-0">
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mb-12">
      <v-card-title v-t="'search'" />

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="recruiters"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
        }"
      >
        <!-- name -->
        <template #[`header.fullName`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.user.email`]="{ header }">
          {{ $t(header.text, { postfix: '' }) }}
        </template>
        <template #[`header.access`]="{ header }">
          {{ $t(header.text, { postfix: '' }) }}
        </template>
        <template #[`header.status`]="{ header }">
          {{ $t(header.text, { postfix: '' }) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.fullName`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
              <span v-else class="font-weight-medium">{{
                avatarText(`${item.user.firstName} ${item.user.lastName}`)
              }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-user-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.user.firstName }} {{ item.user.lastName }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.access`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserAccessVariant(item.access)"
              :class="`v-avatar-light-bg ${resolveUserAccessVariant(item.access)}--text me-3`"
            >
              <v-icon size="18" :color="resolveUserAccessVariant(item.access)">
                {{ resolveUserAccessIcon(item.access) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ $t(item.access) }}</span>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ $t(item.status ? 'active' : 'pending') }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-user-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span v-t="'details'" />
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'

import useUsersList from './useUsersList'

export default {
  setup() {
    const isAddNewUserSidebarActive = ref(false)

    const {
      userListTable,
      tableColumns,
      searchQuery,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      overview,
      recruiters,
      fetchUsers,
      resolveUserAccessVariant,
      resolveUserAccessIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      delaySearchHandler,
    } = useUsersList()

    return {
      avatarText,
      resolveUserAccessVariant,
      resolveUserAccessIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchUsers,
      delaySearchHandler,

      userListTable,
      tableColumns,
      searchQuery,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      recruiters,
      overview,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
