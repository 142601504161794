var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-row',{staticClass:"mb-5"},_vm._l((_vm.overview),function(total){return _c('v-col',{key:total.title,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.count)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(total.title)))])]),_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserTotalIcon(total.title).color) + "--text"),attrs:{"color":_vm.resolveUserTotalIcon(total.title).color}},[_c('v-icon',{staticClass:"rounded-0",attrs:{"size":"25","color":_vm.resolveUserTotalIcon(total.title).color}},[_vm._v(" "+_vm._s(_vm.resolveUserTotalIcon(total.title).icon)+" ")])],1)],1)],1)],1)}),1),_c('v-card',{staticClass:"mb-12"},[_c('v-card-title',{directives:[{name:"t",rawName:"v-t",value:('search'),expression:"'search'"}]}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":_vm.$t('search'),"outlined":"","hide-details":"","dense":""},on:{"keydown":_vm.delaySearchHandler},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.recruiters,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-text': _vm.$t('rowsPerPage'),
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.fullName",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.user.email",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, { postfix: '' }))+" ")]}},{key:"header.access",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, { postfix: '' }))+" ")]}},{key:"header.status",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, { postfix: '' }))+" ")]}},{key:"header.actions",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.fullName",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(((item.user.firstName) + " " + (item.user.lastName)))))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'apps-user-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName)+" ")])],1)],1)]}},{key:"item.access",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserAccessVariant(item.access)) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveUserAccessVariant(item.access)}},[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveUserAccessVariant(item.access)}},[_vm._v(" "+_vm._s(_vm.resolveUserAccessIcon(item.access))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t(item.access)))])],1)]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[_vm._v(" "+_vm._s(_vm.$t(item.status ? 'active' : 'pending'))+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'apps-user-view', params: { id: item.id } }}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',{directives:[{name:"t",rawName:"v-t",value:('details'),expression:"'details'"}]})],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }